export default {
	"locale": "ru",
	"long": {
		"year": {
			"previous": "в прошлом году",
			"current": "в этом году",
			"next": "в следующем году",
			"past": {
				"one": "{0} год назад",
				"many": "{0} лет назад",
				"other": "{0} года назад"
			},
			"future": {
				"one": "через {0} год",
				"many": "через {0} лет",
				"other": "через {0} года"
			}
		},
		"quarter": {
			"previous": "в прошлом квартале",
			"current": "в текущем квартале",
			"next": "в следующем квартале",
			"past": {
				"one": "{0} квартал назад",
				"many": "{0} кварталов назад",
				"other": "{0} квартала назад"
			},
			"future": {
				"one": "через {0} квартал",
				"many": "через {0} кварталов",
				"other": "через {0} квартала"
			}
		},
		"month": {
			"previous": "в прошлом месяце",
			"current": "в этом месяце",
			"next": "в следующем месяце",
			"past": {
				"one": "{0} месяц назад",
				"many": "{0} месяцев назад",
				"other": "{0} месяца назад"
			},
			"future": {
				"one": "через {0} месяц",
				"many": "через {0} месяцев",
				"other": "через {0} месяца"
			}
		},
		"week": {
			"previous": "на прошлой неделе",
			"current": "на этой неделе",
			"next": "на следующей неделе",
			"past": {
				"one": "{0} неделю назад",
				"many": "{0} недель назад",
				"other": "{0} недели назад"
			},
			"future": {
				"one": "через {0} неделю",
				"many": "через {0} недель",
				"other": "через {0} недели"
			}
		},
		"day": {
			"previous": "вчера",
			"previous-2": "позавчера",
			"current": "сегодня",
			"next": "завтра",
			"next-2": "послезавтра",
			"past": {
				"one": "{0} день назад",
				"many": "{0} дней назад",
				"other": "{0} дня назад"
			},
			"future": {
				"one": "через {0} день",
				"many": "через {0} дней",
				"other": "через {0} дня"
			}
		},
		"hour": {
			"current": "в этот час",
			"past": {
				"one": "{0} час назад",
				"many": "{0} часов назад",
				"other": "{0} часа назад"
			},
			"future": {
				"one": "через {0} час",
				"many": "через {0} часов",
				"other": "через {0} часа"
			}
		},
		"minute": {
			"current": "в эту минуту",
			"past": {
				"one": "{0} минуту назад",
				"many": "{0} минут назад",
				"other": "{0} минуты назад"
			},
			"future": {
				"one": "через {0} минуту",
				"many": "через {0} минут",
				"other": "через {0} минуты"
			}
		},
		"second": {
			"current": "сейчас",
			"past": {
				"one": "{0} секунду назад",
				"many": "{0} секунд назад",
				"other": "{0} секунды назад"
			},
			"future": {
				"one": "через {0} секунду",
				"many": "через {0} секунд",
				"other": "через {0} секунды"
			}
		}
	},
	"short": {
		"year": {
			"previous": "в прошлом г.",
			"current": "в этом г.",
			"next": "в след. г.",
			"past": {
				"many": "{0} л. назад",
				"other": "{0} г. назад"
			},
			"future": {
				"many": "через {0} л.",
				"other": "через {0} г."
			}
		},
		"quarter": {
			"previous": "последний кв.",
			"current": "текущий кв.",
			"next": "следующий кв.",
			"past": "{0} кв. назад",
			"future": "через {0} кв."
		},
		"month": {
			"previous": "в прошлом мес.",
			"current": "в этом мес.",
			"next": "в следующем мес.",
			"past": "{0} мес. назад",
			"future": "через {0} мес."
		},
		"week": {
			"previous": "на прошлой нед.",
			"current": "на этой нед.",
			"next": "на следующей нед.",
			"past": "{0} нед. назад",
			"future": "через {0} нед."
		},
		"day": {
			"previous": "вчера",
			"previous-2": "позавчера",
			"current": "сегодня",
			"next": "завтра",
			"next-2": "послезавтра",
			"past": "{0} дн. назад",
			"future": "через {0} дн."
		},
		"hour": {
			"current": "в этот час",
			"past": "{0} ч назад",
			"future": "через {0} ч"
		},
		"minute": {
			"current": "в эту минуту",
			"past": "{0} мин. назад",
			"future": "через {0} мин."
		},
		"second": {
			"current": "сейчас",
			"past": "{0} сек. назад",
			"future": "через {0} сек."
		}
	},
	"narrow": {
		"year": {
			"previous": "в пр. г.",
			"current": "в эт. г.",
			"next": "в сл. г.",
			"past": {
				"many": "-{0} л.",
				"other": "-{0} г."
			},
			"future": {
				"many": "+{0} л.",
				"other": "+{0} г."
			}
		},
		"quarter": {
			"previous": "посл. кв.",
			"current": "тек. кв.",
			"next": "след. кв.",
			"past": "-{0} кв.",
			"future": "+{0} кв."
		},
		"month": {
			"previous": "в пр. мес.",
			"current": "в эт. мес.",
			"next": "в след. мес.",
			"past": "-{0} мес.",
			"future": "+{0} мес."
		},
		"week": {
			"previous": "на пр. нед.",
			"current": "на эт. нед.",
			"next": "на след. нед.",
			"past": "-{0} нед.",
			"future": "+{0} нед."
		},
		"day": {
			"previous": "вчера",
			"previous-2": "позавчера",
			"current": "сегодня",
			"next": "завтра",
			"next-2": "послезавтра",
			"past": "-{0} дн.",
			"future": "+{0} дн."
		},
		"hour": {
			"current": "в этот час",
			"past": "-{0} ч",
			"future": "+{0} ч"
		},
		"minute": {
			"current": "в эту минуту",
			"past": "-{0} мин",
			"future": "+{0} мин"
		},
		"second": {
			"current": "сейчас",
			"past": "-{0} с",
			"future": "+{0} с"
		}
	},
	"now": {
		"now": {
			"current": "сейчас",
			"past": "только что",
			"future": "сейчас"
		}
	},
	"mini": {
		"year": {
			"many": "{0} л",
			"other": "{0} г"
		},
		"month": "{0} мес",
		"week": "{0} нед",
		"day": "{0} д",
		"hour": "{0} ч",
		"minute": "{0} мин",
		"second": "{0} с",
		"now": "сейчас"
	},
	"short-time": {
		"year": {
			"many": "{0} л.",
			"other": "{0} г."
		},
		"month": "{0} мес.",
		"week": "{0} нед.",
		"day": {
			"one": "{0} д.",
			"other": "{0} дн."
		},
		"hour": "{0} ч.",
		"minute": "{0} мин.",
		"second": "{0} сек."
	},
	"long-time": {
		"year": {
			"one": "{0} год",
			"many": "{0} лет",
			"other": "{0} года"
		},
		"month": {
			"one": "{0} месяц",
			"many": "{0} месяцев",
			"other": "{0} месяца"
		},
		"week": {
			"one": "{0} неделю",
			"many": "{0} недель",
			"other": "{0} недели"
		},
		"day": {
			"one": "{0} день",
			"few": "{0} дня",
			"other": "{0} дней"
		},
		"hour": {
			"one": "{0} час",
			"many": "{0} часов",
			"other": "{0} часа"
		},
		"minute": {
			"one": "{0} минута",
			"many": "{0} минут",
			"other": "{0} минуты"
		},
		"second": {
			"one": "{0} секунда",
			"many": "{0} секунд",
			"other": "{0} секунды"
		}
	}
}